import { graphql, StaticQuery } from "gatsby";
import React, { useState } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

const query = graphql`
  query SiteTitleQuery404 {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      location
      openingHours
      contactNumber
      companyEmailAddress
    }
  }
`;

const NotFoundPage = (props) => {
  const [showNav, setShowNav] = useState(true);
  function handleShowNav() {
    setShowNav(true);
  }
  function handleHideNav() {
    setShowNav(false);
  }

  return (
    <StaticQuery
      query={query}
      render={data => {
        if (!data.site) {
          throw new Error(
            'Missing "Site settings". Open the Studio at http://localhost:3333 and some content in "Site settings"'
          );
        }
        return (
          <Layout
            {...props}
            showNav={true}
            site={data.site}
            onHideNav={handleHideNav}
            onShowNav={handleShowNav}
          >
            <SEO title="404: Not found" />
            <h1>NOT FOUND</h1>
            <p>You just hit a route that doesn&#39;t exist...</p>
          </Layout>
        );
      }}
    />
  );
};

export default NotFoundPage;
